/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { Fragment, useMemo } from 'react'
import { Card, Form as AntForm, FormItemProps } from 'antd'
import { FormItem, Select, Checkbox, Form } from 'formik-antd'
import {
	Grid,
	LabelWithDescription,
	Container,
	Autotouch,
	Autolocalize,
	FormikPhoneInput,
	UserInteraction,
	MultiselectFilter,
	FormikInput,
	FormikTextArea,
} from '@libs/components'
import { Formik, FormikTouched } from 'formik'
import {
	CasesApi,
	IAddress,
	IBranch,
	ICasePropertyDetails,
	IContactPerson,
	IElectronicInvoiceParams,
	IEndUserInvoiceParams,
	IUser,
	mapUserAccessToUser,
	OrderStatus,
} from '@libs/api'
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import ContactPerson from '../ContactPerson'
import { Help24, UserFollow24 } from '@carbon/icons-react'
import OrderSummary from '../OrderSummary'
import Styled from './Styled'
import { useNewOrderStepThreeData } from './hooks'
import { useLocalization } from '@libs/localization'
import { createValidationSchema } from '@libs/util'
import { useScreenMatch, ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME } from '@libs/theme'
import merge from 'lodash/merge'
import { INewOrderState } from '../context'
import OrderFormAddress from './OrderFormAddress'

export interface IPropertyStepThreeFormValues {
	departmentId: IBranch['id']
	address: string
	addressFloor?: string
	addressAppartmentNumber?: string
	property?: ICasePropertyDetails
	hasExternalStylist: boolean
	keyPickup?: boolean
	keyPickupAddress?: string
	keyPickupAddressFloor?: string
	keyPickupAddressAppartmentNumber?: string
	shouldSendEndUserInvoice: boolean
	endUserInvoice: Partial<Omit<IEndUserInvoiceParams, 'address'>> & {
		address?: string | IAddress
		addressFloor?: string
		addressAppartmentNumber?: string
		name?: string
	}
	shouldSendElectronicInvoice: boolean
	electronicInvoice: Partial<Omit<IElectronicInvoiceParams, 'address'>> & {
		address?: string | IAddress
		addressFloor?: string
		addressAppartmentNumber?: string
	}
	photographerComment: string
	responsibleUserId: IUser['id']
	contactPersons: IContactPerson[]
	watchers: Partial<IUser>[]
	anyTaskIncludesBooking: boolean
}

function makeTooltip(title: string): FormItemProps['tooltip'] {
	return {
		title,
		icon: <Help24 />,
		placement: 'bottom',
	}
}

const SortableItem = SortableElement(
	({ itemIndex, onRemove }: { itemIndex: number; onRemove: (newList: IContactPerson[]) => void }) => (
		<Fragment>
			<ContactPerson index={itemIndex} onRemove={onRemove} />
			<Styled.SectionDivider />
		</Fragment>
	)
)

const SortableList = SortableContainer(
	({
		contactPersons = [],
		onRemove,
	}: {
		contactPersons: IContactPerson[]
		onRemove: (newList: IContactPerson[]) => void
	}) => (
		<div>
			{contactPersons.map((_, index) => (
				<SortableItem
					key={index}
					index={index}
					itemIndex={index}
					disabled={contactPersons.length === 1}
					onRemove={onRemove}
				/>
			))}
		</div>
	)
)

const NewOrderStepThree = () => {
	const {
		orderState,
		setOrderState,
		user,
		departmentMembers,
		departmentMemberOptions,
		areDepartmentMembersLoading,
		propertyTypeOptions,
		arePropertyTypesLoading,
		department,
		maxPropertyLivingAreaValue,
		selectedDepartmentId,
		setSelectedDepartmentId,
		departmentOptions,
		providerSupportsSubpremise,
		anyTaskIncludesBooking,
		showModal,
		filterCategories,
	} = useNewOrderStepThreeData()

	const showEndUserInvoice = !!department?.endUserInvoicing
	const showBusinessInvoice = !!department?.businessInvoicing
	const isSwedishClient = user?.branches?.[0]?.countryCode === 'SE'

	const initialFormValues: IPropertyStepThreeFormValues = useMemo(
		() => ({
			departmentId: orderState.departmentId ?? '',
			address: (orderState.address?.full ??
				(typeof orderState.address === 'string' ? orderState.address : '')) as string,
			hasExternalStylist: orderState.hasExternalStylist ?? false,
			photographerComment: orderState.photographerComment ?? '',
			shouldSendEndUserInvoice: !!orderState.endUserInvoice,
			endUserInvoice: {
				...orderState.endUserInvoice,
				address: orderState.endUserInvoice?.address?.full || orderState.endUserInvoice?.address,
				addressFloor: orderState.endUserInvoice?.address?.floor,
				addressAppartmentNumber: orderState.endUserInvoice?.address?.appartmentNumber,
			},
			shouldSendElectronicInvoice: !!orderState.electronicInvoice,
			electronicInvoice: {
				...orderState.electronicInvoice,
				address: orderState.electronicInvoice?.address?.full || orderState.electronicInvoice?.address,
				addressFloor: orderState.electronicInvoice?.address?.floor,
				addressAppartmentNumber: orderState.electronicInvoice?.address?.appartmentNumber,
			},
			responsibleUserId: orderState.responsibleUser?.id ?? user?.id ?? departmentMembers[0]?.id ?? '',
			contactPersons: orderState.contactPersons?.map(contactPerson => ({
				...contactPerson,
				phoneAlt: !contactPerson.phoneAlt
					? undefined
					: (contactPerson.phoneAlt.includes('+')
							? contactPerson.phoneAlt
							: `+${user?.branches?.[0]?.countryCode === 'SE' ? '46' : '45'}${contactPerson.phoneAlt}`
					  )
							.replaceAll(/-/g, '')
							.replaceAll(/\(/g, '')
							.replaceAll(/\)/g, '')
							.replaceAll(/ /g, ''),
				phone: !contactPerson.phone
					? undefined
					: (contactPerson.phone.includes('+')
							? contactPerson.phone
							: `+${user?.branches?.[0]?.countryCode === 'SE' ? '46' : '45'}${contactPerson.phone}`
					  )
							.replaceAll(/-/g, '')
							.replaceAll(/\(/g, '')
							.replaceAll(/\)/g, '')
							.replaceAll(/ /g, ''),
			})) ?? [{ type: '', name: '', phone: '', phoneAlt: '' }],
			watchers: orderState.watchers ?? (user ? [user] : []),
			...(orderState.packageType === 'property' && {
				keyPickup: !!orderState.keyPickupAddress,
				keyPickupAddress: orderState.keyPickupAddress?.full ?? '',
				property: orderState.property ?? {},
			}),
			anyTaskIncludesBooking,
		}),
		[user, departmentMembers, orderState, anyTaskIncludesBooking]
	)

	const formSchema = useMemo(
		() =>
			createValidationSchema(yup =>
				yup.object().shape({
					...(anyTaskIncludesBooking && {
						address: yup.string().required(),
						endUserInvoice: yup.object().when('shouldSendEndUserInvoice', {
							is: true,
							then: yup.object().shape({
								name: yup.string().required().max(100),
								phone: yup.string().min(8).phone().required(),
								email: yup.string().email().required(),
								address: yup.string().required(),
							}),
						}),
					}),
					contactPersons: yup
						.array()
						.of(
							yup.object().shape({
								type: yup.string().required(),
								name: yup.string().required(),
								phone: yup.string().required().min(8).phone(),
								// phoneAlt: yup.string().min(8).phone(),
								email: yup.string().email().nullable(),
							})
						)
						.min(1),
					responsibleUserId: yup.string().required(),
					electronicInvoice: yup.object().when('shouldSendElectronicInvoice', {
						is: true,
						then: yup.object().shape({
							name: yup.string().required().max(100),
							cvr: yup.string().numeric().required(),
							ean: yup.string().numeric().optional().nullable().length(13),
							phone: yup.string().min(8).phone().required(),
							email: yup.string().email().required(),
							address: yup.string().required(),
							comment: yup.string().required(),
						}),
					}),
					...(orderState.packageType === 'property' &&
						anyTaskIncludesBooking && {
							property: yup.object().shape({
								type: yup.string().required(),
								livingArea:
									typeof maxPropertyLivingAreaValue === 'number' && maxPropertyLivingAreaValue !== 0
										? yup.number().required().min(0).max(maxPropertyLivingAreaValue)
										: yup.number().required().min(0),
								basementArea: yup.number().required().min(0),
								floors: yup.number().required().min(0),
							}),
							keyPickupAddress: yup.string().when('keyPickup', {
								is: true,
								then: yup.string().required(),
							}),
						}),
				})
			),
		[maxPropertyLivingAreaValue, orderState.packageType, anyTaskIncludesBooking]
	)

	const { t } = useLocalization()
	const match = useScreenMatch()

	const initialTouched = useMemo<FormikTouched<IPropertyStepThreeFormValues>>(() => {
		const setToched = (obj: Record<string, any>): Record<string, any> =>
			Object.keys(obj).reduce((memo, next) => {
				if (obj[next] || obj[next] === 0 || obj[next] === false) {
					if (Array.isArray(obj[next]) && typeof obj[next]?.[0] === 'object') {
						return {
							...memo,
							[next]: obj[next].map((innerObject: Record<string, any>) => setToched(innerObject)),
						}
					}
					if (
						typeof obj[next] === 'string' ||
						typeof obj[next] === 'number' ||
						typeof obj[next] === 'boolean' ||
						Array.isArray(obj[next])
					) {
						return {
							...memo,
							[next]: true,
						}
					}

					return {
						...memo,
						[next]: setToched(obj[next]),
					}
				}

				return memo
			}, {})

		return setToched(initialFormValues)
	}, [initialFormValues])

	return (
		<Formik<IPropertyStepThreeFormValues>
			initialValues={initialFormValues}
			validationSchema={formSchema}
			enableReinitialize
			validateOnMount
			initialTouched={initialTouched}
			onSubmit={async ({
				addressFloor,
				addressAppartmentNumber,
				keyPickup,
				keyPickupAddressFloor,
				keyPickupAddressAppartmentNumber,
				responsibleUserId,
				property,
				shouldSendEndUserInvoice,
				endUserInvoice: {
					address: endUserInvoiceAddressLine,
					addressFloor: endUserInvoiceAddressFloor,
					addressAppartmentNumber: endUserInvoiceAddressAppartmentNumber,
					...endUserInvoiceParams
				},
				shouldSendElectronicInvoice,
				electronicInvoice: {
					address: electronicInvoiceAddressLine,
					addressFloor: electronicInvoiceAddressFloor,
					addressAppartmentNumber: electronicInvoiceAdressAppartmentNumber,
					...electronicInvoiceParams
				},
				...restValues
			}: IPropertyStepThreeFormValues) => {
				const foundResponsible = departmentMembers.find(candidate => candidate.id === responsibleUserId)

				return setOrderState(prevState => ({
					...prevState,
					...restValues,
					address: prevState.address,
					...(anyTaskIncludesBooking && {
						property: {
							...property,
							type: propertyTypeOptions.find(({ value }) => value === property?.type)?.value || property?.type,
						},
					}),
					endUserInvoice:
						shouldSendEndUserInvoice && showEndUserInvoice
							? {
									...(endUserInvoiceParams as IEndUserInvoiceParams),
									firstName: endUserInvoiceParams.name?.split(' ')?.[0] || '',
									lastName: endUserInvoiceParams.name?.substring(endUserInvoiceParams.name.indexOf(' ') + 1) || '',
									address: {
										...(prevState.endUserInvoice?.address as IAddress),
										floor: providerSupportsSubpremise
											? prevState.endUserInvoice?.address.floor
											: endUserInvoiceAddressFloor,
										appartmentNumber: providerSupportsSubpremise
											? prevState.endUserInvoice?.address?.appartmentNumber
											: endUserInvoiceAddressAppartmentNumber,
									},
							  }
							: undefined,
					electronicInvoice:
						shouldSendElectronicInvoice && showBusinessInvoice
							? {
									...(electronicInvoiceParams as IElectronicInvoiceParams),
									address: {
										...(prevState.electronicInvoice?.address as IAddress),
										floor: providerSupportsSubpremise
											? prevState.electronicInvoice?.address?.floor
											: electronicInvoiceAddressFloor,
										appartmentNumber: providerSupportsSubpremise
											? prevState.electronicInvoice?.address?.appartmentNumber
											: electronicInvoiceAdressAppartmentNumber,
									},
							  }
							: undefined,
					keyPickupAddress: keyPickup
						? {
								...(prevState.keyPickupAddress as IAddress),
								floor: providerSupportsSubpremise ? prevState.keyPickupAddress?.floor : keyPickupAddressFloor,
								appartmentNumber: providerSupportsSubpremise
									? prevState.keyPickupAddress?.appartmentNumber
									: keyPickupAddressAppartmentNumber,
						  }
						: undefined,
					responsibleUser: foundResponsible ? mapUserAccessToUser(foundResponsible) : undefined,
					status: 'processing', // Because state update is async we need to set this flag so that the actual submit effect picks off
				}))
			}}
		>
			{({ values, setFieldValue, isValid, submitForm, isSubmitting }) => {
				const mergeOrderState = (updates: RecursivePartialType<INewOrderState>) =>
					setOrderState(prevState =>
						merge(
							{},
							prevState,
							{
								departmentId: values.departmentId,
								hasExternalStylist: values.hasExternalStylist,
								photographerComment: values.photographerComment,
								responsibleUser: {
									id: values.responsibleUserId,
								},
								contactPersons: values.contactPersons,
								watchers: values.watchers,
								property: values.property,
								anyTaskIncludesBooking,
								...(values.shouldSendEndUserInvoice && { endUserInvoice: values.endUserInvoice }),
								...(values.shouldSendElectronicInvoice && { electronicInvoice: values.electronicInvoice }),
							},
							updates
						)
					)

				const trySubmit = async () => {
					const response = await CasesApi.getDatahubCases({
						page: 0,
						pageSize: 100,
						departmentId: [values.departmentId],
						query: orderState.case?.reference,
						status: [OrderStatus.Open],
						category: filterCategories,
					})

					if (
						response.items.some(
							({ id, orderStatus }) => id === orderState.case?.reference && orderStatus === OrderStatus.Open
						)
					) {
						showModal('order.new.already.open', { onConfirm: submitForm })
						return
					}
					await submitForm()
				}

				const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
					setFieldValue('contactPersons', arrayMove(values.contactPersons, oldIndex, newIndex))
					setOrderState(prev => ({
						...prev,
						contactPersons: Array.isArray(prev.contactPersons)
							? arrayMove(prev.contactPersons, oldIndex, newIndex)
							: prev.contactPersons,
					}))
				}

				return (
					<Form layout="vertical">
						<Autolocalize />
						<Grid.Row>
							<Grid.Col span={24} xxl={16}>
								<UserInteraction enabled={!isSubmitting}>
									<Grid.Row>
										<Grid.Col span={24}>
											<Card title={t('orders.pages.new.stepThree.generalInformationCardTitle')}>
												<Container margin={{ bottom: 'md' }}>
													<Container space={'xxs'} spaceDirection={'horizontal'}>
														<Styled.SectionTitle $inline>
															{t('orders.pages.new.stepThree.caseInformationMessage')}
														</Styled.SectionTitle>
														<Styled.SectionTitle $primary $inline>
															{orderState.case?.reference}
														</Styled.SectionTitle>
													</Container>
												</Container>
												{!orderState.explicitlySelectedDepartmentId &&
													!!user?.branches.length &&
													user.branches.length > 1 && (
														<Fragment>
															<Form.Item name={'departmentId'} label={t('common.terms.department')}>
																<Select
																	name={'departmentId'}
																	value={selectedDepartmentId}
																	options={departmentOptions}
																	showArrow
																	showSearch
																	filterOption={(input, option) =>
																		option?.label?.toString().toLowerCase().includes(input) ?? false
																	}
																	onChange={value => {
																		setSelectedDepartmentId(value)
																		// Reset responsible & watchers
																		setFieldValue('responsibleUserId', initialFormValues.responsibleUserId)
																		setFieldValue('watchers', initialFormValues.watchers)
																	}}
																/>
															</Form.Item>
															<Styled.SectionDivider />
														</Fragment>
													)}
												{anyTaskIncludesBooking && (
													<>
														<FormItem name={'address'} label={t('common.terms.address')} required>
															<OrderFormAddress
																name="address"
																idCheck={typeof orderState.address !== 'string'}
																mergeUpdate={newValue => ({
																	address: newValue,
																})}
																onChange={(option: any) => {
																	if (option.data.__internal_address) {
																		mergeOrderState({ address: option.data.__internal_address })
																	}
																}}
															/>
															<Styled.EditButton
																type="link"
																onClick={e => {
																	e.stopPropagation()
																	showModal('address.edit', {
																		address: orderState.address,
																		onSubmit: async (customAddress: IAddress) => {
																			mergeOrderState({ address: customAddress })
																		},
																	})
																}}
															>
																{t('common.actions.editManually')}
															</Styled.EditButton>
														</FormItem>
														{orderState.packageType === 'property' && (
															<>
																<FormItem name={'property.type'} label={t('common.terms.propertyType')} required>
																	<Select
																		showSearch
																		value={propertyTypeOptions.length > 0 ? values.property?.type : undefined}
																		placeholder={t('orders.pages.new.stepThree.propertyTypeLabel')}
																		name={'property.type'}
																		loading={arePropertyTypesLoading}
																		filterOption={(input, option: any) =>
																			option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
																		}
																	>
																		{propertyTypeOptions.map(({ value, label }, index) => (
																			<Select.Option key={index} value={value}>
																				{label}
																			</Select.Option>
																		))}
																	</Select>
																</FormItem>
																<Autotouch
																	name={'property.livingArea'}
																	validate
																	enabled={
																		typeof initialFormValues.property?.livingArea === 'number' &&
																		(typeof maxPropertyLivingAreaValue !== 'number' ||
																			initialFormValues.property.livingArea > maxPropertyLivingAreaValue)
																	}
																>
																	<FormItem
																		name={'property.livingArea'}
																		label={t('orders.pages.new.stepThree.livingAreaLabel')}
																		required
																	>
																		<Styled.InputNumber
																			min={0}
																			type="number"
																			name={'property.livingArea'}
																			onChange={livingArea =>
																				mergeOrderState({ property: { livingArea: livingArea as number } })
																			}
																		/>
																	</FormItem>
																</Autotouch>
																<FormItem
																	name={'property.basementArea'}
																	label={t('orders.pages.new.stepThree.basementAreaLabel')}
																	required
																>
																	<Styled.InputNumber
																		min={0}
																		type="number"
																		name={'property.basementArea'}
																		onChange={basementArea =>
																			mergeOrderState({ property: { basementArea: basementArea as number } })
																		}
																	/>
																</FormItem>
																<FormItem
																	name={'property.floors'}
																	label={t('orders.pages.new.stepThree.numberOfFloorsLabel')}
																	required
																>
																	<Styled.InputNumber
																		min={0}
																		type="number"
																		name={'property.floors'}
																		onChange={floors => mergeOrderState({ property: { floors: floors as number } })}
																	/>
																</FormItem>
																<Styled.SectionDivider />
																<Styled.FormItemCheckbox
																	name={'keyPickup'}
																	tooltip={match.map({
																		xxs: makeTooltip(t('orders.pages.new.stepThree.keyPickupInstructions')),
																		md: undefined,
																	})}
																	label={
																		<LabelWithDescription
																			description={match.map({
																				xxs: undefined,
																				md: t('orders.pages.new.stepThree.keyPickupInstructions'),
																			})}
																		>
																			{t('orders.pages.new.stepThree.keyPickupLabel')}
																		</LabelWithDescription>
																	}
																>
																	<Checkbox name={'keyPickup'}>
																		{t('orders.pages.new.stepThree.keyPickupAction')}
																	</Checkbox>
																</Styled.FormItemCheckbox>
																{values.keyPickup && (
																	<>
																		<FormItem
																			name={'keyPickupAddress'}
																			label={t('common.terms.keyPickupAddress')}
																			required
																		>
																			<OrderFormAddress
																				name="keyPickupAddress"
																				mergeUpdate={newValue => ({
																					keyPickupAddress: newValue,
																				})}
																				onChange={(option: any) => {
																					if (option.data.__internal_address) {
																						mergeOrderState({
																							keyPickupAddress: option.data.__internal_address,
																						})
																					}
																				}}
																			/>
																			<Styled.EditButton
																				type="link"
																				onClick={e => {
																					e.stopPropagation()
																					showModal('address.edit', {
																						address: orderState.keyPickupAddress,
																						onSubmit: async (customAddress: IAddress) => {
																							mergeOrderState({
																								keyPickupAddress: customAddress,
																							})
																						},
																					})
																				}}
																			>
																				{t('common.actions.editManually')}
																			</Styled.EditButton>
																		</FormItem>
																		{!providerSupportsSubpremise && (
																			<Grid.Row gutter={['sm', 0]}>
																				<Grid.Col xs={24} md={12}>
																					<FormItem
																						name={'keyPickupAddressFloor'}
																						label={t('common.terms.floor')}
																						className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																					>
																						<FormikInput name={'keyPickupAddressFloor'} />
																					</FormItem>
																				</Grid.Col>
																				<Grid.Col xs={24} md={12}>
																					<FormItem
																						name={'keyPickupAddressAppartmentNumber'}
																						label={t('common.terms.appartmentNumber')}
																						className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																					>
																						<FormikInput name={'keyPickupAddressAppartmentNumber'} />
																					</FormItem>
																				</Grid.Col>
																			</Grid.Row>
																		)}
																	</>
																)}
																{!isSwedishClient && (
																	<>
																		<Styled.SectionDivider />
																		<Styled.FormItemCheckbox
																			name={'hasExternalStylist'}
																			tooltip={match.map({
																				xxs: makeTooltip(t('orders.pages.new.stepThree.externalStylistInstructions')),
																				md: undefined,
																			})}
																			label={
																				<LabelWithDescription
																					description={match.map({
																						xxs: undefined,
																						md: t('orders.pages.new.stepThree.externalStylistInstructions'),
																					})}
																				>
																					{t('orders.pages.new.stepThree.externalStylistLabel')}
																				</LabelWithDescription>
																			}
																		>
																			<Checkbox name={'hasExternalStylist'}>
																				{t('orders.pages.new.stepThree.externalStylistAction')}
																			</Checkbox>
																		</Styled.FormItemCheckbox>
																	</>
																)}
															</>
														)}
													</>
												)}

												{showEndUserInvoice && (
													<>
														<Styled.FormItemCheckbox
															name={'shouldSendEndUserInvoice'}
															tooltip={match.map({
																xxs: makeTooltip(t('orders.pages.new.stepThree.endUserInvoiceDescription')),
																md: undefined,
															})}
															label={
																<LabelWithDescription
																	description={match.map({
																		xxs: undefined,
																		md: t('orders.pages.new.stepThree.endUserInvoiceDescription'),
																	})}
																>
																	{t('orders.pages.new.stepThree.endUserInvoiceLabel')}
																</LabelWithDescription>
															}
														>
															<Checkbox name={'shouldSendEndUserInvoice'} disabled={values.shouldSendElectronicInvoice}>
																{t('orders.pages.new.stepThree.endUserInvoiceAction')}
															</Checkbox>
														</Styled.FormItemCheckbox>
														{values.shouldSendEndUserInvoice && (
															<Fragment>
																<FormItem name={'endUserInvoice.name'} label={t('common.terms.name')} required>
																	<FormikInput name={'endUserInvoice.name'} />
																</FormItem>
																<Grid.Row gutter={['sm', 0]}>
																	<Grid.Col xs={24} md={12}>
																		<FormItem
																			name={'endUserInvoice.email'}
																			label={t('common.terms.email')}
																			required
																			className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																		>
																			<FormikInput name={'endUserInvoice.email'} />
																		</FormItem>
																	</Grid.Col>
																	<Grid.Col xs={24} md={12}>
																		<FormItem
																			name={'endUserInvoice.phone'}
																			label={t('common.terms.phone')}
																			required
																			className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																		>
																			<FormikPhoneInput
																				country={user?.branches?.[0]?.countryCode?.toLowerCase()}
																				value={values.endUserInvoice.phone}
																				name={'endUserInvoice.phone'}
																			/>
																		</FormItem>
																	</Grid.Col>
																</Grid.Row>
																<FormItem name={'endUserInvoice.address'} label={t('common.terms.address')} required>
																	<OrderFormAddress
																		name="endUserInvoice.address"
																		mergeUpdate={newValue => ({
																			endUserInvoice: {
																				...values.endUserInvoice,
																				address: newValue,
																			},
																		})}
																		onChange={(option: any) => {
																			if (option.data.__internal_address) {
																				mergeOrderState({
																					endUserInvoice: {
																						...values.endUserInvoice,
																						address: option.data.__internal_address,
																					},
																				})
																			}
																		}}
																	/>
																	<Styled.EditButton
																		type="link"
																		onClick={e => {
																			e.stopPropagation()
																			showModal('address.edit', {
																				address: orderState.endUserInvoice?.address,
																				onSubmit: async (customAddress: IAddress) => {
																					mergeOrderState({
																						endUserInvoice: {
																							...values.endUserInvoice,
																							address: customAddress,
																						},
																					})
																				},
																			})
																		}}
																	>
																		{t('common.actions.editManually')}
																	</Styled.EditButton>
																</FormItem>
																{!providerSupportsSubpremise && (
																	<Grid.Row gutter={['sm', 0]}>
																		<Grid.Col xs={24} md={12}>
																			<FormItem
																				name={'endUserInvoice.addressFloor'}
																				label={t('common.terms.floor')}
																				className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																			>
																				<FormikInput name={'endUserInvoice.addressFloor'} />
																			</FormItem>
																		</Grid.Col>
																		<Grid.Col xs={24} md={12}>
																			<FormItem
																				name={'endUserInvoice.addressAppartmentNumber'}
																				label={t('common.terms.appartmentNumber')}
																				className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																			>
																				<FormikInput name={'endUserInvoice.addressAppartmentNumber'} />
																			</FormItem>
																		</Grid.Col>
																	</Grid.Row>
																)}
															</Fragment>
														)}
														{/* <Styled.SectionDivider /> */}
													</>
												)}
												{showBusinessInvoice && (
													<>
														<Styled.SectionDivider />
														<Styled.FormItemCheckbox
															name={'shouldSendElectronicInvoice'}
															tooltip={match.map({
																xxs: makeTooltip(t('orders.pages.new.stepThree.electronicInvoiceDescription')),
																md: undefined,
															})}
															label={
																<LabelWithDescription
																	description={match.map({
																		xxs: undefined,
																		md: t('orders.pages.new.stepThree.electronicInvoiceDescription'),
																	})}
																>
																	{t('orders.pages.new.stepThree.electronicInvoiceLabel')}
																</LabelWithDescription>
															}
														>
															<Checkbox name={'shouldSendElectronicInvoice'} disabled={values.shouldSendEndUserInvoice}>
																{t('orders.pages.new.stepThree.electronicInvoiceAction')}
															</Checkbox>
														</Styled.FormItemCheckbox>
													</>
												)}
												{values.shouldSendElectronicInvoice && (
													<Fragment>
														<FormItem name={'electronicInvoice.name'} label={t('common.terms.name')} required>
															<FormikInput name={'electronicInvoice.name'} />
														</FormItem>
														<Grid.Row gutter={['sm', 0]}>
															<Grid.Col xs={24} md={12}>
																<FormItem
																	name={'electronicInvoice.email'}
																	label={t('common.terms.email')}
																	required
																	className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																>
																	<FormikInput name={'electronicInvoice.email'} />
																</FormItem>
															</Grid.Col>
															<Grid.Col xs={24} md={12}>
																<FormItem
																	name={'electronicInvoice.phone'}
																	label={t('common.terms.phone')}
																	required
																	className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																>
																	<FormikPhoneInput
																		country={user?.branches?.[0]?.countryCode?.toLowerCase()}
																		value={values.electronicInvoice.phone}
																		name={'electronicInvoice.phone'}
																	/>
																</FormItem>
															</Grid.Col>
														</Grid.Row>
														<Grid.Row gutter={['sm', 0]}>
															<Grid.Col xs={24} md={12}>
																<FormItem
																	name={'electronicInvoice.cvr'}
																	label={t('common.terms.cvr')}
																	required
																	className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																>
																	<FormikInput name={'electronicInvoice.cvr'} />
																</FormItem>
															</Grid.Col>
															<Grid.Col xs={24} md={12}>
																<FormItem
																	name={'electronicInvoice.ean'}
																	label={t('common.terms.ean')}
																	className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																>
																	<FormikInput name={'electronicInvoice.ean'} />
																</FormItem>
															</Grid.Col>
														</Grid.Row>
														<FormItem name={'electronicInvoice.address'} label={t('common.terms.address')} required>
															<OrderFormAddress
																name="electronicInvoice.address"
																mergeUpdate={newValue => ({
																	electronicInvoice: {
																		...values.electronicInvoice,
																		address: newValue,
																	},
																})}
																onChange={(option: any) => {
																	if (option.data.__internal_address) {
																		mergeOrderState({
																			electronicInvoice: {
																				...values.electronicInvoice,
																				address: option.data.__internal_address,
																			},
																		})
																	}
																}}
															/>
															<Styled.EditButton
																type="link"
																onClick={e => {
																	e.stopPropagation()
																	showModal('address.edit', {
																		address: orderState.electronicInvoice?.address,
																		onSubmit: async (customAddress: IAddress) => {
																			mergeOrderState({
																				electronicInvoice: {
																					...values.electronicInvoice,
																					address: customAddress,
																				},
																			})
																		},
																	})
																}}
															>
																{t('common.actions.editManually')}
															</Styled.EditButton>
														</FormItem>
														<Grid.Row gutter={['sm', 0]}>
															<Grid.Col xs={24}>
																<FormItem
																	name={'electronicInvoice.comment'}
																	label={t('orders.pages.new.stepThree.electronicInvoiceCommentLabel')}
																	required
																	className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																>
																	<FormikTextArea
																		name={'electronicInvoice.comment'}
																		autoSize={{ minRows: 2, maxRows: 4 }}
																	/>
																</FormItem>
															</Grid.Col>
														</Grid.Row>
														{!providerSupportsSubpremise && (
															<Grid.Row gutter={['sm', 0]}>
																<Grid.Col xs={24} md={12}>
																	<FormItem
																		name={'electronicInvoice.addressFloor'}
																		label={t('common.terms.floor')}
																		className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																	>
																		<FormikInput name={'electronicInvoice.addressFloor'} />
																	</FormItem>
																</Grid.Col>
																<Grid.Col xs={24} md={12}>
																	<FormItem
																		name={'electronicInvoice.addressAppartmentNumber'}
																		label={t('common.terms.appartmentNumber')}
																		className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
																	>
																		<FormikInput name={'electronicInvoice.addressAppartmentNumber'} />
																	</FormItem>
																</Grid.Col>
															</Grid.Row>
														)}
													</Fragment>
												)}
												{anyTaskIncludesBooking && (
													<>
														<Styled.SectionDivider />
														<FormItem
															name={'photographerComment'}
															tooltip={match.map({
																xxs: makeTooltip(t('orders.pages.new.stepThree.photographerCommentInstructions')),
																md: undefined,
															})}
															label={
																<LabelWithDescription
																	description={match.map({
																		xxs: undefined,
																		md: t('orders.pages.new.stepThree.photographerCommentInstructions'),
																	})}
																>
																	{t('common.terms.photographerComment')}
																</LabelWithDescription>
															}
														>
															<FormikTextArea name={'photographerComment'} autoSize={{ minRows: 2, maxRows: 4 }} />
														</FormItem>
													</>
												)}
											</Card>
										</Grid.Col>
										<Grid.Col span={24}>
											<Card title={t('orders.pages.new.stepThree.contactPersonsCardTitle')}>
												<SortableList
													contactPersons={values.contactPersons}
													onSortEnd={onSortEnd}
													onRemove={newList =>
														setOrderState(prev => ({
															...prev,
															contactPersons: newList,
														}))
													}
												/>
												{values.contactPersons.length < 3 && (
													<Styled.ButtonWithIcon
														type="primary"
														onClick={() =>
															setFieldValue(
																'contactPersons',
																values.contactPersons?.concat([
																	{
																		type: '',
																		name: '',
																		phone: '',
																		phoneAlt: '',
																		email: '',
																	},
																])
															)
														}
													>
														<UserFollow24 />
														{t('orders.pages.new.stepThree.addExtraContactAction')}
													</Styled.ButtonWithIcon>
												)}
											</Card>
										</Grid.Col>
										<Grid.Col span={24}>
											<Card title={t('orders.pages.new.stepThree.watchersCardTitle')}>
												<Container margin={{ bottom: 'md' }}>
													<Styled.SectionTitle>{department?.name}</Styled.SectionTitle>
												</Container>
												<FormItem name={'responsibleUserId'} label={t('common.terms.responsibleAgent')} required>
													<Select
														loading={areDepartmentMembersLoading}
														name={'responsibleUserId'}
														options={departmentMembers.map(({ fullName, id }) => ({
															label: fullName,
															value: id,
														}))}
													/>
												</FormItem>
												<AntForm.Item
													tooltip={match.map({
														xxs: makeTooltip(t('orders.pages.new.stepThree.watchersInstructions')),
														md: undefined,
													})}
													label={
														<LabelWithDescription
															description={match.map({
																xxs: undefined,
																md: t('orders.pages.new.stepThree.watchersInstructions'),
															})}
														>
															{t('orders.pages.new.stepThree.watchersLabel')}
														</LabelWithDescription>
													}
												>
													<Container margin={{ top: 'xxs' }}>
														<MultiselectFilter
															name="watchers"
															value={values.watchers.map(({ id }) => id!)}
															placeholder={t('orders.pages.new.stepThree.chooseWatchersAction')}
															showArrow
															maxTagCount={10}
															onChange={newWatchers =>
																setFieldValue(
																	'watchers',
																	departmentMembers.filter(({ id }) => (newWatchers as string[]).includes(id))
																)
															}
															onSelectAll={(fieldName: string) => setFieldValue(fieldName, departmentMembers)}
															options={departmentMemberOptions}
														/>
													</Container>
												</AntForm.Item>
											</Card>
										</Grid.Col>
									</Grid.Row>
								</UserInteraction>
							</Grid.Col>
							<Grid.Col span={24} xxl={8}>
								<OrderSummary
									disabled={!isValid}
									onContinue={trySubmit}
									sticky={match.eq('xxl')}
									submitBtnText={t('orders.pages.new.stepThree.submit')}
									loading={orderState.status === 'processing'}
								/>
							</Grid.Col>
						</Grid.Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default NewOrderStepThree
